import axios from "./Repository";
import { ModelSettings, AppearanceSettings } from "../types";

export function getPreferences(googleDocId: string): Promise<{ data: any }> {
  return axios.get(`preferences/${googleDocId}`);
}

export function syncPreferences(
  googleDocId: string,
  preferences: ModelSettings & AppearanceSettings
): Promise<{ data: any }> {
  return axios.post(`preferences/${googleDocId}/sync`, preferences);
}
