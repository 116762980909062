import React, { useState } from "react";
import Modal from "../../../components/modal";
import { ModelSettings, AppearanceSettings } from "../../../types";
import Switch from "../../../components/switch";
import { SlWrench } from "react-icons/sl";

type SettingsModalProps = {
  isVisible: boolean;
  modelSettings: ModelSettings;
  appearanceSettings: AppearanceSettings;
  chatId: string;
  toogleVisibility: (visibility: boolean) => void;
  onSaveClick: (
    modelSettings: ModelSettings,
    appearanceSettings: AppearanceSettings
  ) => void;
  onFreshChatClick: () => void;
};

export default function SettingsModal({
  isVisible,
  modelSettings,
  appearanceSettings,
  chatId,
  toogleVisibility,
  onSaveClick,
  onFreshChatClick,
}: SettingsModalProps) {
  const [writingTemperature, setWritingTemperature] = useState<number>(
    modelSettings.writingTemperature
  );
  const [isFineTunedModelEnabled, setIsFineTunedModelEnabled] =
    useState<boolean>(modelSettings.isFineTunedModelEnabled);
  const [messageFontSize, setMessageFontSize] = useState<number>(
    appearanceSettings.messageFontSize
  );
  return (
    <Modal
      show={isVisible}
      handleClose={() => {
        toogleVisibility(false);
      }}
    >
      {/* <SlWrench color="white" size={15} /> */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label>Writing Temperature</label>
          <input
            className="primary-input"
            type="number"
            step={0.1}
            min={0.0}
            max={2.0}
            value={writingTemperature}
            onChange={(e) => {
              setWritingTemperature(parseFloat(e.target.value));
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <label>Fine-Tuned Model (beta)</label>
          <Switch
            checked={isFineTunedModelEnabled}
            onChange={(checked) => {
              setIsFineTunedModelEnabled(checked);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <label>Message Font Size</label>
          <input
            className="primary-input"
            type="number"
            style={{ width: "35px" }} // Forefully giving width because it's somehow not picking up the auto width
            step={1}
            min={0}
            value={messageFontSize}
            onChange={(e) => {
              setMessageFontSize(parseInt(e.target.value));
            }}
          />
        </div>
        <button
          className="primary-button"
          style={{ padding: "10px", marginTop: "20px" }}
          onClick={() => {
            onSaveClick(
              {
                ...modelSettings,
                writingTemperature,
                isFineTunedModelEnabled,
              },
              { ...appearanceSettings, messageFontSize }
            );
            toogleVisibility(false);
          }}
        >
          Save
        </button>
        <hr style={{ width: "100%", borderWidth: "0.5px" }} />
        <button
          className="primary-button"
          style={{ padding: "10px" }}
          onClick={() => {
            onFreshChatClick();
            toogleVisibility(false);
          }}
        >
          Start Fresh Chat
        </button>
      </div>
    </Modal>
  );
}
