import React from "react";
import "./style.css";
import { SlClose } from "react-icons/sl";

export type ModalProps = {
  show: boolean;
  children: any;
  modalStyle?: { width: any } | null;
  handleClose: () => void;
};

export default function Modal({
  show,
  children,
  modalStyle = null,
  handleClose,
}: ModalProps) {
  return (
    <div className={`modal ${show ? "display-block" : "display-none"}`}>
      <section className="modal-main" style={modalStyle || {}}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SlClose
            color="white"
            size={15}
            onClick={handleClose}
            style={{ alignSelf: "end", marginBottom: "10px" }}
          />
          {children}
        </div>
      </section>
    </div>
  );
}
