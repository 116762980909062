import React, { useState, useRef, useEffect, useCallback } from "react";
import "./style.css";
import { useParams, useLocation } from "react-router-dom";
import ChatBubble from "./chat-bubble";
import ChatInput from "./chat-input";
import SettingsModal from "./settings-modal";
import InfoModal from "./info-modal";
import { io, Socket } from "socket.io-client";
import { SlSettings, SlInfo } from "react-icons/sl";
import { HashLoader } from "react-spinners";
import {
  ChatMessage,
  ChatBubbleMessage,
  DocumentInfo,
  WindowEvent,
  GetDocumentIdAndMarkdownEvent,
  ModelSettings,
  AppearanceSettings,
} from "../../types";
import { getMessages } from "../../repositories/ChatRepository";
import {
  getPreferences,
  syncPreferences,
} from "../../repositories/PreferencesRepository";

// This must match the formatting done by `ProposeSectionDraft` in the backend.
const MAGIC_DRAFT_PATTERN = /^<p>Here is the .* that I was working on:<\/p>/;
const HTML_TAGS_PATTERN = /<[^>]*>/g;

const editorCursor = "<#>"; //this denotes the editor's cursor in the document

//// Manage the length of the markdown string sent to the backend for extremely long Google Docs.
// Allow 50% of gpt-4-turbo's max input length, assuming an average of
// about 3.5 characters per token.
const MAX_MARKDOWN_CHARS = Math.round(128000 * 3.5 * 0.5);
const PRIORITY_MARKDOWN_CHARS_AT_START = Math.round(MAX_MARKDOWN_CHARS / 2);
const PRIORITY_MARKDOWN_CHARS_PRECEDING_CURSOR = Math.round(
  MAX_MARKDOWN_CHARS / 4
);

export default function Chat() {
  const { organizationId } = useParams();
  const location = useLocation();

  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [pendingChatHistory, setPendingChatHistory] = useState<ChatMessage[]>(
    []
  );
  const [bubbles, setBubbles] = useState<ChatBubbleMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const [isUserTyping, setIsUserTyping] = useState<boolean>(false);
  const [chatId, setChatId] = useState<string>();
  const [googleDocumentId, setGoogleDocumentId] = useState<string>();
  const [documentInfo, setDocumentInfo] = useState<DocumentInfo | null>(null);
  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    useState<boolean>(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);
  const [modelSettings, setModelSettings] = useState<ModelSettings>({
    writingTemperature: 0.5,
    isFineTunedModelEnabled: false,
  });
  const [appearanceSettings, setAppearanceSettings] =
    useState<AppearanceSettings>({
      messageFontSize: 12,
    });

  const chatIdRef = useRef<string>();
  const documentInfoRef = useRef<DocumentInfo | null>(null);
  const socketRef = useRef<Socket | null>(null);

  chatIdRef.current = chatId;
  documentInfoRef.current = documentInfo;

  const appendBubble = useCallback((bubble: ChatBubbleMessage) => {
    setBubbles((current) => [...current, bubble]);
  }, []);

  useEffect(() => {
    const windowMessageListener = (event: WindowEvent) => {
      if (event.data.functionName === "getDocumentIdAndMarkdown") {
        const docEvent = event as GetDocumentIdAndMarkdownEvent;
        const idAndMarkdown = docEvent.data.idAndMarkdown;
        const newlineIndex = idAndMarkdown.indexOf("\n");
        const docId = idAndMarkdown.substring(0, newlineIndex);
        const markdown = idAndMarkdown.substring(newlineIndex + 1);

        let newChatId = `${docId}_${organizationId}`;

        if (
          documentInfoRef.current &&
          documentInfoRef.current.markdown !== markdown
        ) {
          notifyDocumentUpdate(markdown, newChatId, docId);
        }

        setGoogleDocumentId(docId);
        setDocumentInfo({
          googleDocumentId: docId,
          markdown: markdown,
        });

        if (!chatIdRef.current) {
          // This is our first look at the document.
          if (!organizationId) {
            throw new Error("Organization ID not set");
          }
          setChatId(newChatId);
          console.log(`Will soon set chat id: ${newChatId}`);
        } else {
          console.log(`Already have chat ID: ${chatIdRef.current}`);
        }
      }
    };

    const socketMessageListener = (chatMessage: ChatMessage) => {
      setChatHistory((current) => [...current, chatMessage]);
      if (isUserTyping) {
        setPendingChatHistory((current) => [...current, chatMessage]);
      } else {
        handleChatMessage(chatMessage);
      }
    };

    try {
      socketRef.current = io(process.env.REACT_APP_SERVER_URL || "");
      if (window && window.parent) {
        window.parent.postMessage(
          { functionName: "startDocumentWatcher" },
          "*"
        );
        window.addEventListener("message", windowMessageListener);
      }
      socketRef.current?.on("message", socketMessageListener);
    } catch (error) {
      console.error("Error setting up socket connection: ", error);
    }

    return () => {
      console.warn("##### unmounting Chat; disconnecting socket #####");
      socketRef.current?.disconnect();
      window.removeEventListener("message", windowMessageListener);
    };
  }, []);

  const writeToDocument = (content: string) => {
    if (window && window.parent) {
      window.parent.postMessage(
        { functionName: "writeToDocument", content: content },
        "*"
      );
    }
  };

  /**
   * Limit the length of a markdown string to a predefined maximum, inserting ellipses
   * where text is removed.
   *
   * If the cursor is present in the string, as determined by a `EDITOR_CURSOR` marker, then
   * preferentially preserve text surrounding the cursor.
   *
   * @param {string} markdown - The original markdown string.
   * @returns {string} The truncated markdown string, preserving the cursor position if present.
   */
  const limitMarkdownLength = useCallback((markdown: string): string => {
    const ELLIPSES = " ... ";
    const ELLIPSES_LEN = ELLIPSES.length;

    const cursorIndex = markdown.indexOf(editorCursor);
    if (markdown.length <= MAX_MARKDOWN_CHARS) {
      // no need to drop any text
      return markdown;
    }
    if (cursorIndex === -1) {
      // too long, but no cursor, so just truncate
      return (
        markdown.substring(0, MAX_MARKDOWN_CHARS - ELLIPSES_LEN) + ELLIPSES
      );
    }
    let markdownBeforeCursor: string;
    if (
      cursorIndex <=
      PRIORITY_MARKDOWN_CHARS_AT_START +
        PRIORITY_MARKDOWN_CHARS_PRECEDING_CURSOR
    ) {
      // the high-priority regions before the cursor are contiguous
      markdownBeforeCursor = markdown.substring(0, cursorIndex);
    } else {
      markdownBeforeCursor =
        markdown.substring(0, PRIORITY_MARKDOWN_CHARS_AT_START) +
        ELLIPSES +
        markdown.substring(
          cursorIndex - PRIORITY_MARKDOWN_CHARS_PRECEDING_CURSOR,
          cursorIndex
        );
    }
    let markdownAfterCursor: string;
    const remainingCapacity = MAX_MARKDOWN_CHARS - markdownBeforeCursor.length;
    if (remainingCapacity >= markdown.length - cursorIndex) {
      markdownAfterCursor = markdown.substring(cursorIndex);
    } else {
      markdownAfterCursor =
        markdown.substring(
          cursorIndex,
          cursorIndex + remainingCapacity - ELLIPSES_LEN
        ) + ELLIPSES;
    }
    return markdownBeforeCursor + markdownAfterCursor;
  }, []);

  const promptAssistant = useCallback(
    (updatedChatHistory?: ChatMessage[]) => {
      if (!chatId) {
        throw new Error("Chat ID not set");
      }
      let localHistory: ChatMessage[] = updatedChatHistory || [...chatHistory];
      if (!documentInfo) {
        console.error("documentInfo not set");
      }
      setIsTyping(true);
      try {
        socketRef.current?.emit("DocUserSubmit", {
          chat_history: localHistory,
          chat_id: chatId,
          organization_id: organizationId,
          google_document_id: googleDocumentId,
          document_content: documentInfo
            ? limitMarkdownLength(documentInfo.markdown)
            : "",
          writing_temperature: modelSettings.writingTemperature,
          is_fine_tuned_model_enabled: modelSettings.isFineTunedModelEnabled,
          user_name: location.state.userName,
          user_email: location.state.email,
          user_message: localHistory[localHistory.length - 1].content,
        });
      } catch (error) {
        console.error("Error sending message: ", error);
      }
    },
    [
      chatId,
      documentInfo,
      chatHistory,
      socketRef,
      organizationId,
      googleDocumentId,
      limitMarkdownLength,
      modelSettings,
      location,
    ]
  );

  const notifyDocumentUpdate = (
    markdown: string,
    chatId: string,
    googleDocumentId: string
  ) => {
    if (chatId && googleDocumentId) {
      try {
        socketRef.current?.emit("DocUpdate", {
          chat_id: chatId,
          organization_id: organizationId,
          google_document_id: googleDocumentId,
          document_content: markdown,
          user_name: location.state.userName,
          user_email: location.state.email,
        });
      } catch (error) {
        console.error("Error notifying documentm update: ", error);
      }
    } else {
      console.log("do not have chat ID");
    }
  };

  const sendDefaultGreetingMessage = useCallback(() => {
    let oldChatHistory: ChatMessage[] = [];
    oldChatHistory.push({
      role: "user",
      content:
        "Hello, my assistant! Just briefly, who are you and in what ways can you help me?",
      hidden: true,
    });
    setChatHistory(oldChatHistory);
    promptAssistant(oldChatHistory);
  }, [promptAssistant]);

  const startFreshChat = useCallback(() => {
    setIsLoading(true);
    socketRef.current?.emit("StartFreshChat", { chat_id: chatId });

    // hackey way will fix it later
    socketRef.current?.on("ChatCleared", () => {
      setChatHistory([]);
      setBubbles([]);
      setIsLoading(false);
      sendDefaultGreetingMessage();
    });
  }, [chatId, sendDefaultGreetingMessage]);

  const handleChatMessage = useCallback(
    (chatMessage: ChatMessage) => {
      if (
        !chatMessage.hidden &&
        chatMessage.role === "assistant" &&
        chatMessage.content
      ) {
        const content = chatMessage.content as string;
        if (content.match(MAGIC_DRAFT_PATTERN)) {
          let draft = content
            .split(MAGIC_DRAFT_PATTERN)
            [content.split(MAGIC_DRAFT_PATTERN).length - 1].replace(
              HTML_TAGS_PATTERN,
              ""
            );
          let draftBubble: ChatBubbleMessage = {
            role: "assistant",
            content: content,
            buttons: [
              {
                label: "Insert",
                onClick: () => {
                  writeToDocument(draft);
                },
              },
            ],
            clipped: true,
          };
          appendBubble(draftBubble);
        } else {
          let speechBubble: ChatBubbleMessage = {
            role: "assistant",
            content: content,
            clipped: false,
          };
          appendBubble(speechBubble);
          if (
            speechBubble.content.charAt(0) !== "(" &&
            speechBubble.content.charAt(speechBubble.content.length - 1) !== ")"
          ) {
            setIsTyping(false);
          }
        }
      }
    },
    [appendBubble]
  );

  // The chatId should only change once, from null to its final value.
  // When this happens, fetch older messages in the chat if any and then
  // give the assistant a chance to speak.
  useEffect(
    () => {
      if (chatId) {
        // promptAssistant();
        console.log("chat ID", chatId);
        getPreferences(googleDocumentId || "")
          .then((response) => {
            console.log("response", response);
            if (response.data.data) {
              let savedModelSettings: ModelSettings = {
                writingTemperature: response.data.data.writingTemperature,
                isFineTunedModelEnabled:
                  response.data.data.isFineTunedModelEnabled,
              };
              let savedAppearanceSettings: AppearanceSettings = {
                messageFontSize: response.data.data.messageFontSize || 12,
              };
              setModelSettings(savedModelSettings);
              setAppearanceSettings(savedAppearanceSettings);
            }
          })
          .catch((error) => {
            console.error("Error is get preferences", error);
          });
        getMessages(chatId)
          .then((data: any) => {
            setIsLoading(false);
            let oldChatHistory: ChatMessage[] = [];
            if (data.messages.length !== 0) {
              setIsTyping(false);
              let messages = data.messages;
              messages.forEach((message: any) => {
                let chatMessage: ChatMessage = {
                  role: message.role,
                };
                chatMessage.content = message.content;
                if (message.name) {
                  chatMessage.name = message.name;
                }
                if (message.function_call) {
                  chatMessage.function_call = {
                    name: message.function_name,
                    arguments: message.function_arguments,
                  };
                }
                if (message.hidden !== undefined) {
                  chatMessage.hidden = message.hidden;
                }
                if (chatMessage.content === "" && !chatMessage.function_call) {
                  //do nothing
                } else {
                  oldChatHistory.push(chatMessage);
                }
              });
              let visibleChatMessages = oldChatHistory.filter(
                (message) =>
                  (message.role === "user" ||
                    (message.role === "assistant" && message.content !== "")) &&
                  !message.hidden
              );
              let chatBubbles: ChatBubbleMessage[] = [];
              visibleChatMessages.forEach((chatMessage: ChatMessage) => {
                if (chatMessage.role === "assistant") {
                  const content = chatMessage.content as string;
                  const match = content.match(MAGIC_DRAFT_PATTERN);
                  if (match) {
                    let draft = content
                      .split(MAGIC_DRAFT_PATTERN)
                      [content.split(MAGIC_DRAFT_PATTERN).length - 1].replace(
                        HTML_TAGS_PATTERN,
                        ""
                      );
                    let draftBubble: ChatBubbleMessage = {
                      role: "assistant",
                      content: content,
                      buttons: [
                        {
                          label: "Insert",
                          onClick: () => {
                            writeToDocument(draft);
                          },
                        },
                      ],
                      clipped: true,
                    };
                    chatBubbles.push(draftBubble);
                  } else {
                    let speechBubble: ChatBubbleMessage = {
                      role: "assistant",
                      content: chatMessage.content as string,
                      clipped: false,
                    };
                    chatBubbles.push(speechBubble);
                  }
                } else {
                  chatBubbles.push(chatMessage as ChatBubbleMessage);
                }
              });
              setChatHistory(oldChatHistory);
              setBubbles(chatBubbles as ChatBubbleMessage[]);
            } else {
              sendDefaultGreetingMessage();
              // oldChatHistory.push({
              //   role: "user",
              //   content:
              //     "Hello, my assistant! Just briefly, who are you and in what ways can you help me?",
              //   hidden: true,
              // });
              // setChatHistory(oldChatHistory);
              // promptAssistant(oldChatHistory);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    // It is important NOT to make this depend on anything but chatId,
    // because we don't want to automatically prompt the assistant
    // on any other changes.
    [chatId]
  );

  useEffect(() => {
    if (!isUserTyping && pendingChatHistory.length !== 0) {
      pendingChatHistory.forEach((message) => handleChatMessage(message));
      setPendingChatHistory([]);
    }
  }, [handleChatMessage, isUserTyping, pendingChatHistory]);

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <HashLoader color="#267ee5" />
        </div>
      ) : (
        <div className="chat-container">
          <SettingsModal
            chatId={chatId || ""}
            modelSettings={modelSettings}
            appearanceSettings={appearanceSettings}
            isVisible={isSettingsModalVisible}
            toogleVisibility={setIsSettingsModalVisible}
            onSaveClick={(modelSettings, appearanceSettings) => {
              setModelSettings(modelSettings);
              setAppearanceSettings(appearanceSettings);
              syncPreferences(googleDocumentId || "", {
                ...modelSettings,
                ...appearanceSettings,
              }).catch((error) => {
                console.error("Error in sync preferences", error);
              });
            }}
            onFreshChatClick={startFreshChat}
          />
          {/* <InfoModal
            isVisible={isInfoModalVisible}
            chatId={chatId || ""}
            toogleVisibility={setIsInfoModalVisible}
          /> */}
          <div className="message-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "end",
                position: "fixed",
              }}
            >
              <SlSettings
                size={15}
                color="white"
                style={{
                  margin: "5px 5px 0px 0px",
                }}
                onClick={() => {
                  setIsSettingsModalVisible(true);
                }}
              />
              {/* <SlInfo
                size={15}
                color="white"
                style={{
                  margin: "5px 5px 0px 0px",
                }}
                onClick={() => {
                  setIsInfoModalVisible(true);
                }}
              /> */}
            </div>
            {bubbles.map((msg, index) => {
              // console.log(msg);
              return (
                <ChatBubble
                  {...msg}
                  isLast={index === bubbles.length - 1}
                  key={index}
                  fontSize={appearanceSettings.messageFontSize}
                />
              );
            })}
            {isTyping ? (
              <ChatBubble content="" role="loading" isLast={true} />
            ) : (
              ""
            )}
          </div>
          <ChatInput
            fontSize={appearanceSettings.messageFontSize}
            onSubmit={(userMessage: string) => {
              let bubble: ChatBubbleMessage = {
                role: "user",
                content: userMessage,
                clipped: false,
              };
              let updatedChatHistory = [...chatHistory, bubble as ChatMessage];
              setChatHistory(updatedChatHistory);
              appendBubble(bubble);
              promptAssistant(updatedChatHistory);
            }}
            onTypingStatusChanged={setIsUserTyping}
          />
        </div>
      )}
    </>
  );
}
