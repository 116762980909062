import React, { useState } from "react";
import "./style.css";

type SwitchProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export default function Switch({ checked, onChange = () => {} }: SwitchProps) {
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <div>
      <input
        className="toggle-switch"
        type="checkbox"
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          onChange(e.target.checked);
        }}
      />
    </div>
  );
}
